// @ts-nocheck
import { Field } from "react-final-form";
import { RadioField, TitlePage } from "~/components";
import { composeValidators, disabledValidate, required, testRegExp } from "~/utils/validate";
import { Divider } from "antd";
import { Wizard, FormField } from "~/pages/questionnaires/shared";
import { NamesOfFieldEnum } from "~/typings/types";
import { configTitleFieldsOfCompany } from "../configTitleFields";

import styles from "~/pages/questionnaires/shared/Shared.module.scss";

export const PayformDetails = ({
  typeRequestCheckPayformName,
  callbackCheckPayformName,
  productType
}: any) => {
  return (
    <Wizard.Page>
      <TitlePage
        name="Укажите контактные данные вашей организации, которые будут размещены на платёжной странице"
        number="4"
      />
      <Divider style={{ margin: 0 }} />
      <div className={styles.container}>
        <FormField
          titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.PayformName]}
          name={NamesOfFieldEnum.PayformName}
          required
          addonBefore="https://"
          addonAfter=".payform.ru"
          tooltip="Название, которое вы придумаете, будут видеть покупатели. Например, наша платёжная страница называется prodamus.payform.ru"
          validate={composeValidators(
            required(),
            testRegExp(
              /^[a-zA-Z0-9-]{1,25}$/g,
              'Только англ. буквы, цифры и знак "-". Не более 25 символов'
            )
          )}
          orderNumber="1"
          typeRequestCheckPayformName={typeRequestCheckPayformName}
          onBlur={callbackCheckPayformName}
        />

        <FormField
          titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.ProductName]}
          name={NamesOfFieldEnum.ProductName}
          required
          tooltip="Например: доступ к обучающим материалам, косметические наборы"
          validate={composeValidators(required())}
          orderNumber="2"
        />

        {productType && Object.keys(productType).length && (
          <FormField
            titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.ProductType]}
            name={NamesOfFieldEnum.ProductType}
            tooltip="Можно выбрать только один вариант. Если и то и другое подходит, выберите то, на что приходится больше оборота"
            isColon={false}
            required
          >
            <div className={styles.containerRadio}>
              {Object.values(productType).map(({ name, title }) => (
                <Field
                  key={name}
                  name={NamesOfFieldEnum.ProductType}
                  type="radio"
                  value={name}
                  component={props => <RadioField orderNumber="3" {...props} />}
                  validate={disabledValidate(composeValidators(required()))}
                >
                  {title}
                </Field>
              ))}
            </div>
          </FormField>
        )}

        <FormField
          titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.ProductDescription]}
          name={NamesOfFieldEnum.ProductDescription}
          required
          validate={composeValidators(required())}
          typeInput="textArea"
          autoSize={{
            minRows: 7
          }}
          showCount
          maxLength={10000}
          tooltip={`Напишите не менее четырёх предложений. Можно в рекламном духе. 
                			Например: «Онлайн-клуб постановки голоса для беременных и молодых мам: открытые уроки и мастер-классы по тренировке голоса, 
                			полезные и простые упражнения, интересные интервью с профильными специалистами по теме беременности, материнства и здоровья. 
                			Получи красивый голос, занимаясь всего 15 минут в день, без отрыва от счастья материнства! 
                			А также: обучение искусству колыбельной песни, сочинения и рассказывания сказок, общение с единомышленницами»
              		`}
          orderNumber="4"
          placement="left"
        />

        <FormField
          titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.PayformLinks]}
          name={NamesOfFieldEnum.PayformLinks}
          tooltip="Если ресурсов несколько, то укажите все через запятую"
          validate={composeValidators(required())}
          required
          orderNumber="5"
        />

        <FormField
          titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.ShopName]}
          name={NamesOfFieldEnum.ShopName}
          required
          validate={composeValidators(required())}
          tooltip="Например: онлайн-марафон «Как перейти на удалённую работу». Или: интернет-магазин «Ромашка». Или: магазин диетических товаров «Авокадо». Или: тренинги Ивана Иванова"
          orderNumber="6"
        />
      </div>
    </Wizard.Page>
  );
};
