export const GENERATE_UNIQUE_ID = "api/questionnaire/v1/helper/generate-unique-id";
export const DRAFT_SHORTCUT_HASH_INFO = "api/questionnaire/v1/helper/draft-shortcut-hash-info";
export const HELPER_FILE = "/api/questionnaire/v1/helper/file";
export const CHECK_PAYFORM_EXISTS = "/api/questionnaire/v1/helper/check-payform-exists";
export const GET_BANK_INFO = "/api/questionnaire/v1/helper/get-bank-info";
export const DRAFT_PREVIEW = "/api/questionnaire/v1/draft-preview";
export const REPORT_ERROR = "/api/questionnaire/v1/questionnaire/report-error";
export const REQUEST_CODE = "/api/questionnaire/v1/confirmation/request-code";
export const CONFIRM_CODE = "/api/questionnaire/v1/confirmation/confirm-code";
export const CHANGE_PHONE = "/api/questionnaire/v1/draft-edit/phone-change";
export const PERSONAL_DATA_ADDRESS_SUGGEST = "/api/questionnaire/v1/personal-data/address/suggest";

export const PARTNER_DRAFT_ACCOUNT_DETAILS = "/api/questionnaire/v1/draft-partner/account-details";
export const PARTNER_DRAFT_COMPANY_DETAILS = "/api/questionnaire/v1/draft-partner/company-details";
export const PARTNER_DRAFT_PAYFORM_DETAILS = "/api/questionnaire/v1/draft-partner/payform-details";
export const PARTNER_DRAFT_ATTACHMENTS_DETAILS = "/api/questionnaire/v1/draft-partner/attachment-details";
export const PARTNER_DRAFT_SAVE = "/api/questionnaire/v1/questionnaire-partner/save";