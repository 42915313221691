import { NameStepsEnum, NameStepsEnumPartner } from "~/typings/types";
import { getTypeQuestionnaires } from "~/utils";

// Порядок шагов в форме
export const configStepsCustomer: Array<NameStepsEnum | string> = [
  "default",
  NameStepsEnum.AccountDetails,
  NameStepsEnum.CompanyDetails,
  NameStepsEnum.ContactDetails,
  NameStepsEnum.PayformDetails,
  NameStepsEnum.PayformTechDetails,
  NameStepsEnum.MarketplaceDetails,
  NameStepsEnum.AdditionalDetails,
  NameStepsEnum.AttachmentsDetails
];

// Порядок шагов в форме partner
export const configStepsPartner: Array<NameStepsEnum | string> = [
  "default",
  NameStepsEnumPartner.AccountDetails,
  NameStepsEnumPartner.CompanyDetails,
  NameStepsEnumPartner.PayformDetails,
  NameStepsEnumPartner.AttachmentsDetails
];

export const configSteps = getTypeQuestionnaires() === "partner" ? configStepsPartner : configStepsCustomer;