import { BrowserRouter, Route, Routes, Navigate, useLocation } from "react-router-dom";
import { Layout } from "~/components/layout";
import {
  ErrorPage,
  EmailSuccessPage,
  EmailFailedPage,
  CustomerQuestionnairePage,
  PartnerQuestionnairePage
} from "~/pages";
import { SentryContrainer } from "~/containers";
import { routes } from "./configs/routes";

import "~/assets/styles/style.scss";
import "~/assets/icons/index";

const PreserveQueryNavigate = ({ to }: { to: string }) => {
  const location = useLocation();
  const search = location.search;

  return <Navigate replace to={`${to}${search}`} />;
};

export const App = (): JSX.Element => {
  return (
    <SentryContrainer>
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" element={<PreserveQueryNavigate to={routes.customer} />} />
            <Route path={routes.customer} element={<CustomerQuestionnairePage />} />
            <Route path={routes.partner} element={<PartnerQuestionnairePage />} />
            <Route path={routes.emailSuccess} element={<EmailSuccessPage />} />
            <Route path={routes.emailFailed} element={<EmailFailedPage />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </SentryContrainer>
  );
};