import { Field, FormSpy } from "react-final-form";
import { TitlePage, PhoneField, RadioField, CheckboxField } from "~/components";
import { composeValidators, disabledValidate, required, testRegExp } from "~/utils/validate";
import { Divider } from "antd";
import { Wizard, FormField } from "~/pages/questionnaires/shared";
import { NamesOfFieldEnum } from "~/typings/types";
import { configTitleFieldsOfCompany } from "../configTitleFields";
import cn from "classnames";

import styles from "~/pages/questionnaires/shared/Shared.module.scss";

const configPartnerships = {
  xl: {
    name: "xl",
    title: "Prodamus.XL"
  },
  teleboss: {
    name: "teleboss",
    title: "Prodamus.Teleboss"
  }
};

interface Props {
  queryPromocode: string | null;
}

export const AccountDetails = ({ queryPromocode }: Props) => {
  return (
    <Wizard.Page>
      <TitlePage
        name="Укажите ваши данные для регистрации аккаунта в системе Prodamus"
        number="2"
      />
      <Divider style={{ margin: 0 }} />
      <div className={styles.container}>
        <FormField
          titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.CrmName]}
          name={NamesOfFieldEnum.CrmName}
          required
          validate={composeValidators(required())}
          orderNumber="1"
        />

        <FormField
          titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.CrmEmail]}
          name={NamesOfFieldEnum.CrmEmail}
          required
          validate={composeValidators(
            required(),
            testRegExp(
              /^\s*\S+@\S+\.\S+\s*$/,
              "Пожалуйста, укажите корректный адрес электронной почты"
            )
          )}
          orderNumber="2"
        />

        <FormField
          titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.CrmPhone]}
          name={NamesOfFieldEnum.CrmPhone}
          required
        >
          <Field
            name={NamesOfFieldEnum.CrmPhone}
            subscription={{ submitFailed: true, error: true, submitError: true, value: true }}
            component={props => <PhoneField orderNumber="3" {...props} />}
            validate={disabledValidate(composeValidators(required()))}
          />
        </FormField>

        <FormField
          titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.CrmTelegram]}
          name={NamesOfFieldEnum.CrmTelegram}
          orderNumber="4"
        />

        <FormField
          titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.CrmSocialLinks]}
          name={NamesOfFieldEnum.CrmSocialLinks}
          orderNumber="5"
        />

        <FormField
          titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.CrmOccupation]}
          name={NamesOfFieldEnum.CrmOccupation}
        >
          <div className={cn(styles.containerRadio, styles.partnerLevel_containerRadio)}>
            <Field
              name={NamesOfFieldEnum.CrmOccupation}
              type="radio"
              value="tech_specialist"
              component={props => <RadioField orderNumber="6" {...props} />}
            >
              Техспец
            </Field>
            <Field
              name={NamesOfFieldEnum.CrmOccupation}
              type="radio"
              value="owner_school"
              component={props => <RadioField orderNumber="6" {...props} />}
            >
              Владелец школы
            </Field>
            <Field
              name={NamesOfFieldEnum.CrmOccupation}
              type="radio"
              value="bloger"
              component={props => <RadioField orderNumber="6" {...props} />}
            >
              Блогер
            </Field>
            <Field
              name={NamesOfFieldEnum.CrmOccupation}
              type="radio"
              value="marketer"
              component={props => <RadioField orderNumber="6" {...props} />}
            >
              Маркетолог
            </Field>
            <Field
              name={NamesOfFieldEnum.CrmOccupation}
              type="radio"
              value="other"
              component={props => <RadioField orderNumber="6" {...props} />}
            >
              Иное
            </Field>
          </div>
        </FormField>

        <FormField
          titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.PromocodeReferral]}
          name={NamesOfFieldEnum.PromocodeReferral}
          disabled={Boolean(queryPromocode)}
          orderNumber="7"
        />

        <FormField
          titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.PromocodeOwn]}
          name={NamesOfFieldEnum.PromocodeOwn}
          orderNumber="8"
          required
          validate={disabledValidate(composeValidators(required()))}
        />

        <FormSpy>
          {propsSpy => {
            const { partner_level } = propsSpy.values;
            return (
              <>
                <FormField
                  titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.PartnerLevel]}
                  name={NamesOfFieldEnum.PartnerLevel}
                  required
                >
                  <div className={cn(styles.containerRadio, styles.partnerLevel_containerRadio)}>
                    <div
                      className={styles.partnerLevel_container}
                      style={{ borderColor: partner_level == 1 ? "rgb(61, 147, 94)" : undefined }}
                    >
                      <Field
                        name={NamesOfFieldEnum.PartnerLevel}
                        type="radio"
                        value="1"
                        component={props => (
                          <RadioField
                            orderNumber="9"
                            {...props}
                            classNameWrapper={styles.partnerLevel_radioBtn_wrapper}
                          />
                        )}
                        validate={disabledValidate(composeValidators(required()))}
                      >
                        <div className={styles.partnerLevel_wrapper_text}>
                          <h4 className={styles.partnerLevel_title}>Первая ступень</h4>
                          <p className={styles.partnerLevel_description}>
                            Фиксированное вознаграждение за каждого реферала в размере 40% от
                            стоимости подключения.
                          </p>
                        </div>
                      </Field>
                    </div>

                    <div
                      className={styles.partnerLevel_container}
                      style={{ borderColor: partner_level == 2 ? "rgb(61, 147, 94)" : undefined }}
                    >
                      <Field
                        name={NamesOfFieldEnum.PartnerLevel}
                        type="radio"
                        value="2"
                        component={props => (
                          <RadioField
                            orderNumber="9"
                            {...props}
                            classNameWrapper={styles.partnerLevel_radioBtn_wrapper}
                          />
                        )}
                        validate={disabledValidate(composeValidators(required()))}
                      >
                        <div className={styles.partnerLevel_wrapper_text}>
                          <h4 className={styles.partnerLevel_title}>Вторая ступень</h4>
                          <p className={styles.partnerLevel_description}>
                            Фиксированное вознаграждение за каждого реферала – 40% от стоимости
                            подключения. Ежемесячная выплата – 20% от валовой прибыли (ВПР), которую
                            каждый ваш реферал приносит Prodamus.
                          </p>
                        </div>
                      </Field>
                    </div>

                    <div
                      className={styles.partnerLevel_container}
                      style={{ borderColor: partner_level == 3 ? "rgb(61, 147, 94)" : undefined }}
                    >
                      <Field
                        name={NamesOfFieldEnum.PartnerLevel}
                        type="radio"
                        value="3"
                        component={props => (
                          <RadioField
                            orderNumber="9"
                            {...props}
                            classNameWrapper={styles.partnerLevel_radioBtn_wrapper}
                          />
                        )}
                        validate={disabledValidate(composeValidators(required()))}
                      >
                        <div className={styles.partnerLevel_wrapper_text}>
                          <h4 className={styles.partnerLevel_title}>Третья ступень</h4>
                          <p className={styles.partnerLevel_description}>
                            То же, что и на второй ступени. Дополнительная ежемесячная выплата – 5%
                            от ВПР, которую приносят рефералы ваших рефералов.
                          </p>
                        </div>
                      </Field>
                    </div>
                  </div>
                </FormField>
              </>
            );
          }}
        </FormSpy>

        <FormField
          titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.Partnerships]}
          name={NamesOfFieldEnum.Partnerships}
        >
          <div className={styles.containerPaymentMethods}>
            {configPartnerships &&
              Object.values(configPartnerships).map((option: any) => (
                <CheckboxField
                  key={option?.name}
                  fieldName={NamesOfFieldEnum.Partnerships}
                  orderNumber="10"
                  {...option}
                />
              ))}
          </div>
        </FormField>
      </div>
    </Wizard.Page>
  );
};
