import {
  NameStepsEnumPartner,
  OrgTypeEnum,
  NamesOfFieldEnum,
  FormData,
  ClientId,
  AccountDetailsFormFieldsPartner,
  SharedTypeRequestFields,
  PayformDetailsFormFieldsPartner,
  CompanyDetailsFormFieldsPartner
} from "~/typings/types";
import { getValuesFromObject } from "~/utils/getValuesFromObject";
import { getWithPrefixPhone } from "~/utils/getWithPrefixPhone";
import {
  postDraftCompanyDetails,
  postDraftAccountDetails,
  ReqPostArguments,
  ResPost,
  postDraftPayformDetails
} from "./apiMethods";
import { getFieldNameWithCondition } from "~/utils";
import { defaultAxiosRequestConfig } from "~/api/apiMethods";

type RequestFunction = ({
  data,
  config
}: ReqPostArguments<SharedTypeRequestFields>) => Promise<ResPost>;
type RequestConfig = Omit<
  Record<NameStepsEnumPartner, RequestFunction>,
  NameStepsEnumPartner.AttachmentsDetails
>;

const requestConfig: RequestConfig = {
  [NameStepsEnumPartner.CompanyDetails]: postDraftCompanyDetails,
  [NameStepsEnumPartner.AccountDetails]: postDraftAccountDetails,
  [NameStepsEnumPartner.PayformDetails]: postDraftPayformDetails
};

interface ApiService {
  values: FormData;
  pageId: Exclude<NameStepsEnumPartner, NameStepsEnumPartner.AttachmentsDetails>;
  clientId: ClientId;
  orgType: OrgTypeEnum;
}

export const apiServicePartner = async ({ values, pageId, clientId, orgType }: ApiService) =>
  await new Promise((resolve, reject) => {
    let reqData: any; // TODO: fixed any type

    if (pageId === NameStepsEnumPartner.CompanyDetails) {
      reqData = {
        id: clientId,
        fields: {
          [NamesOfFieldEnum.OrgType]: orgType,
          ...getValuesFromObject<Omit<CompanyDetailsFormFieldsPartner, NamesOfFieldEnum.OrgType>>({
            object: values,
            values: [
              NamesOfFieldEnum.Inn,
              NamesOfFieldEnum.BankBic,
              NamesOfFieldEnum.BankName,
              NamesOfFieldEnum.SettlementAccount,
              NamesOfFieldEnum.CorrespondentAccount,
              NamesOfFieldEnum.LegalAddress,
              NamesOfFieldEnum.LegalAddressFiasId,
              NamesOfFieldEnum.IsWantCreatePayform,
              getFieldNameWithCondition(orgType === OrgTypeEnum.Company, NamesOfFieldEnum.Ogrn),
              getFieldNameWithCondition(orgType === OrgTypeEnum.Company, NamesOfFieldEnum.Kpp),
              getFieldNameWithCondition(
                orgType === OrgTypeEnum.IndividualEntrepreneur,
                NamesOfFieldEnum.Ogrnip
              )
            ]
          })
        }
      };
    }

    if (pageId === NameStepsEnumPartner.AccountDetails) {
      reqData = {
        id: clientId,
        fields: {
          [NamesOfFieldEnum.OrgType]: orgType,
          [NamesOfFieldEnum.CrmPhone]: getWithPrefixPhone(values?.[NamesOfFieldEnum.CrmPhone]),
          ...getValuesFromObject<
            Omit<
              AccountDetailsFormFieldsPartner,
              NamesOfFieldEnum.OrgType | NamesOfFieldEnum.CrmPhone
            >
          >({
            object: values,
            values: [
              NamesOfFieldEnum.CrmName,
              NamesOfFieldEnum.CrmTelegram,
              NamesOfFieldEnum.CrmEmail,
              NamesOfFieldEnum.CrmSocialLinks,
              NamesOfFieldEnum.CrmOccupation,
              NamesOfFieldEnum.PromocodeReferral,
              NamesOfFieldEnum.PromocodeOwn,
              NamesOfFieldEnum.PartnerLevel,
              NamesOfFieldEnum.Partnerships
            ]
          })
        }
      };
    }

    if (pageId === NameStepsEnumPartner.PayformDetails) {
      reqData = {
        id: clientId,
        fields: {
          [NamesOfFieldEnum.OrgType]: orgType,
          [NamesOfFieldEnum.PayformName]: `https://${values?.payform_name}.payform.ru`,
          ...getValuesFromObject<
            Omit<
              PayformDetailsFormFieldsPartner,
              NamesOfFieldEnum.OrgType | NamesOfFieldEnum.PayformName
            >
          >({
            object: values,
            values: [
              NamesOfFieldEnum.ProductName,
              NamesOfFieldEnum.ProductType,
              NamesOfFieldEnum.ProductDescription,
              NamesOfFieldEnum.PayformLinks,
              NamesOfFieldEnum.ShopName
            ]
          })
        }
      };
    }

    requestConfig?.[pageId]({ data: reqData, config: defaultAxiosRequestConfig })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        console.log("err requestConfig", err);
        reject(err);
      });
  });
