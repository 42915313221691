import { IS_ENABLED_YANDEX_METRIKA } from "~/configs/consts";

export const sendYandexMetrika = (id: number, action: string, goalName: string): void => {
  if (!IS_ENABLED_YANDEX_METRIKA) {
    return;
  }

  try {
    ym(id, action, goalName);
  } catch (error) {
	// TODO: Отсылать ошибку в сентри, в консоль ничего не выводить
    // console.log("Yandex Metrika error:", error);
  }
};
