// @ts-nocheck
import { Field, FormSpy } from "react-final-form";
import { CheckboxField, InputField, TitlePage } from "~/components";
import {
  composeValidators,
  disabledValidate,
  notEmptyValue,
  required,
  testRegExp
} from "~/utils/validate";
import { Divider } from "antd";
import {
  FormField,
  Wizard,
  FormFieldAutocompleteAddress,
  FormFieldBic
} from "~/pages/questionnaires/shared";
import { NamesOfFieldEnum } from "~/typings/types";
import { configTitleFieldsOfSelfEmployed, configTitleFieldsOfBank } from "../configTitleFields";
import {
  GET_BANK_INFO,
  PERSONAL_DATA_ADDRESS_SUGGEST
} from "~/pages/questionnaires/customer/api/apiUrls";

import styles from "~/pages/questionnaires/shared/Shared.module.scss";

export const CompanyDetails = ({ clientId }) => {
  return (
    <Wizard.Page>
      <TitlePage name="Укажите свои реквизиты" number="3" />
      <Divider style={{ margin: 0 }} />
      <div className={styles.container}>
        <FormField
          titleField={configTitleFieldsOfSelfEmployed[NamesOfFieldEnum.Inn]}
          name={NamesOfFieldEnum.Inn}
          required
          maxLength={12}
          validate={composeValidators(
            required(),
            testRegExp(/^(\d){12}$/g, "ИНН должен состоять из 12 цифр")
          )}
          orderNumber="1"
        />

        <FormSpy>
          {propsSpy => {
            return (
              <FormFieldBic
                titleField={configTitleFieldsOfSelfEmployed[NamesOfFieldEnum.BankBic]}
                name={NamesOfFieldEnum.BankBic}
                required
                maxLength={9}
                mask="04"
                validate={composeValidators(
                  required(),
                  testRegExp(/^04(\d){7}$/g, 'БИК должен начинаться на "04" и состоять из 9 цифр')
                )}
                configNameFieldsModalSuggestion={configTitleFieldsOfBank}
                propsSpy={propsSpy}
                clientId={clientId}
                tooltip="БИК — уникальный код банка. Он состоит из девяти цифр"
                urlGetBankInfo={GET_BANK_INFO}
              />
            );
          }}
        </FormSpy>

        <FormField
          titleField={configTitleFieldsOfSelfEmployed[NamesOfFieldEnum.BankName]}
          name={NamesOfFieldEnum.BankName}
          required
          validate={composeValidators(required())}
          orderNumber="3"
        />

        <FormField
          titleField={configTitleFieldsOfSelfEmployed[NamesOfFieldEnum.SettlementAccount]}
          name={NamesOfFieldEnum.SettlementAccount}
          maxLength={20}
          mask="408"
          required
          validate={composeValidators(
            required(),
            testRegExp(
              /^408(\d){17}$/g,
              'Номер расчётного счёта должен начинаться на "408" и состоять из 20 цифр'
            )
          )}
          orderNumber="4"
        />

        <FormField
          titleField={configTitleFieldsOfSelfEmployed[NamesOfFieldEnum.CorrespondentAccount]}
          name={NamesOfFieldEnum.CorrespondentAccount}
          maxLength={20}
          mask="301"
          required
          validate={composeValidators(
            required(),
            testRegExp(
              /^301(\d){17}$/g,
              'Номер корреспондентского счёта должен начинаться на "301" и состоять из 20 цифр'
            )
          )}
          orderNumber="5"
        />

        <FormSpy>
          {propsSpy => {
            return (
              <FormFieldAutocompleteAddress
                titleField={configTitleFieldsOfSelfEmployed[NamesOfFieldEnum.LegalAddress]}
                name={NamesOfFieldEnum.LegalAddress}
                nameFiasId={NamesOfFieldEnum.LegalAddressFiasId}
                required
                validate={composeValidators(required(), notEmptyValue())}
                orderNumber="7"
                propsSpy={propsSpy}
                clientId={clientId}
                urlPersonalDataAddressSuggest={PERSONAL_DATA_ADDRESS_SUGGEST}
              />
            );
          }}
        </FormSpy>

        <FormField name={NamesOfFieldEnum.IsWantCreatePayform} isColon={false}>
          <div>
            <CheckboxField
              key={NamesOfFieldEnum.IsWantCreatePayform}
              fieldName={NamesOfFieldEnum.IsWantCreatePayform}
              validate={disabledValidate(composeValidators())}
              orderNumber="8"
              title={configTitleFieldsOfSelfEmployed[NamesOfFieldEnum.IsWantCreatePayform]}
            />
          </div>
        </FormField>
      </div>
    </Wizard.Page>
  );
};
