import { Dispatch, SetStateAction } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Divider, Spin } from "antd";
import cn from "classnames";
import { TitlePage } from "~/components";
import { Wizard, AcceptPolitics } from "~/pages/questionnaires/shared";
import { AttachmentsDetailsError, NamesOfFieldEnum } from "~/typings/types";

import "~/pages/questionnaires/shared/styles.css";
import styles from "~/pages/questionnaires/shared/Shared.module.scss";
import { useForm } from "react-final-form";

interface Props {
  isLoadingMount: boolean;
  isLoadingLogo: boolean;
  isLoadingPassport: boolean;
  logoFiles: File[];
  setLogoFileId: Dispatch<SetStateAction<number[]>>;
  setLogoFiles: Dispatch<File[]>;
  passportFiles: File[];
  setPassportFileId: Dispatch<SetStateAction<number[]>>;
  setPassportFiles: Dispatch<File[]>;
  logoError: AttachmentsDetailsError;
  passportError: AttachmentsDetailsError;
  setLogoError: Dispatch<AttachmentsDetailsError>;
  setPassportError: Dispatch<AttachmentsDetailsError>;
  COUNT_UPLOAD_LOGO_FILE?: number;
  COUNT_UPLOAD_PASSPORT_FILE?: number;
  isAcceptPolitics: boolean;
  setIsAcceptPolitics: Dispatch<SetStateAction<boolean>>;
  acceptPoliticsError: AttachmentsDetailsError;
  setAcceptPoliticsError: Dispatch<SetStateAction<AttachmentsDetailsError>>;
  setIsFirstLoadingFile: Dispatch<boolean>;
}

export const AttachmentsDetails = ({
  isLoadingMount,
  isAcceptPolitics,
  setIsAcceptPolitics,
  acceptPoliticsError,
  setAcceptPoliticsError
}: Props) => {
  const values = useForm().getState().values;
  const isHidePayformDetailsStep = values?.[NamesOfFieldEnum.IsWantCreatePayform];
  const currentPage = isHidePayformDetailsStep ? "5" : "4";

  return (
    <Wizard.Page>
      <TitlePage name="Ознакомьтесь и примите условия" number={currentPage} />
      <Divider style={{ margin: 0 }} />
      <div className={cn(styles.container, styles.containerUpload)}>
        {isLoadingMount ? (
          <div className={styles.containerLoader}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "#3d935e" }} spin />} />
          </div>
        ) : (
          <>
            <AcceptPolitics
              isAcceptPolitics={isAcceptPolitics}
              setIsAcceptPolitics={setIsAcceptPolitics}
              acceptPoliticsError={acceptPoliticsError}
              setAcceptPoliticsError={setAcceptPoliticsError}
              containerClassName={styles.containerAcceptPoliticsCompany}
            />
          </>
        )}
      </div>
    </Wizard.Page>
  );
};
